<template>
  <div class="container">
    <h2 class="title"></h2>


    <!-- iOS 栏目 -->
    <div class="software-section">
      <h3 class="section-title">
        <i class="fab fa-apple icon"></i> <!-- iOS 图标 -->
        iOS
      </h3>
      <div class="software-list">
        <div class="software-item" v-for="software in iosSoftware" :key="software.id">
          <h4 class="software-title">{{ software.name }}</h4>
          <p class="software-description">{{ software.description }}</p>
          <a :href="software.downloadLink" class="download-button" target="_blank">下载</a>
          <router-link v-if="software.name === 'Shadowrocket'" to="/shadowrocket-guide" class="guide-button">使用说明</router-link>
        </div>
      </div>
    </div>

    <!-- 安卓栏目 -->
    <div class="software-section">
      <h3 class="section-title">
        <i class="fab fa-android icon"></i> <!-- 安卓图标 -->
        安卓
      </h3>
      <div class="software-list">
        <div class="software-item" v-for="software in androidSoftware" :key="software.id">
          <h4 class="software-title">{{ software.name }}</h4>
          <p class="software-description">{{ software.description }}</p>
          <a :href="software.downloadLink" class="download-button" target="_blank">下载</a>
          <a v-if="software.guideLink" :href="software.guideLink" class="guide-button" target="_blank">使用说明</a>
        </div>
      </div>
    </div>

    <!-- Windows 栏目 -->
    <div class="software-section">
      <h3 class="section-title">
        <i class="fab fa-windows icon"></i> <!-- Windows 图标 -->
        Windows
      </h3>
      <div class="software-list">
        <div class="software-item" v-for="software in windowsSoftware" :key="software.id">
          <h4 class="software-title">{{ software.name }}</h4>
          <p class="software-description">{{ software.description }}</p>
          <a :href="software.downloadLink" class="download-button" target="_blank">下载</a>
          <a v-if="software.guideLink" :href="software.guideLink" class="guide-button" target="_blank">使用说明</a>
        </div>
      </div>
    </div>
	
    <!-- 本站自研 栏目 -->
    <div class="software-section">
      <h3 class="section-title">
        <i class="fas fa-code icon"></i> <!-- 自研图标 -->
        本站自研
      </h3>
      <div class="software-list">
        <div class="software-item">
          <h4 class="software-title">开发中，请先用以上软件</h4>
          <p class="software-description">敬请期待我们即将推出的傻瓜式专门软件，致力于提供最佳的用户体验。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iosSoftware: [
        {
          id: 1,
          name: 'Shadowrocket',
          description: 'Shadowrocket 是一款功能强大的 iOS VPN 客户端，支持各种协议和配置。',
          downloadLink: 'https://apps.apple.com/us/app/shadowrocket/id932747118'
        },
        // 添加更多iOS软件条目
      ],
      androidSoftware: [
        {
          id: 1,
          name: 'NekoBox',
          description: 'NekoBox 是一款功能强大的安卓 VPN 客户端，支持包括hy2在内的多种协议。',
          downloadLink: 'https://github.com/Matsuridayo/NekoBoxForAndroid/releases',
          guideLink: 'https://matsuridayo.github.io/nb4a-configuration/'
        },
        {
          id: 2,
          name: 'V2RayNG',
          description: 'V2RayNG 是一个支持多种代理协议的安卓 VPN 客户端，V2RayNG界面及配置方式与NekoBox相似，但是它不支持hy协议。',
          downloadLink: 'https://github.com/2dust/v2rayNG/releases',
          guideLink: 'https://matsuridayo.github.io/nb4a-configuration/'
        },
        // 添加更多安卓软件条目
      ],
      windowsSoftware: [
        {
          id: 1,
          name: 'V2RayN',
          description: 'V2RayN 是一个支持多种代理协议的桌面 VPN 客户端。',
          downloadLink: 'https://github.com/2dust/v2rayN/releases'
        },
        {
          id: 2,
          name: 'NekoRay',
          description: 'NekoRay 是基于v2ray / sing-box 内核的跨平台代理工具客户端。',
          downloadLink: 'https://github.com/Matsuridayo/nekoray/releases',
          guideLink: 'https://nekoray.com/nekoray/'
        },
        // 添加更多Windows软件条目
      ]
    };
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.software-section {
  margin-bottom: 40px;
}

.section-title {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

.icon {
  font-size: 24px;
  margin-right: 10px;
}

.software-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.software-item {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.software-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #333;
}

.software-description {
  margin-bottom: 15px;
  color: #555;
}

.download-button, .guide-button {
  display: inline-block;
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 4px;
  text-align: center;
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.download-button {
  background-color: #007bff;
}

.download-button:hover {
  background-color: #0056b3;
}

.guide-button {
  background-color: #28a745;
}

.guide-button:hover {
  background-color: #218838;
}
</style>
