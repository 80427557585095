<template>
  <div class="container">
    <h2 class="title">注册账号</h2>
    <form @submit.prevent="register" class="form">
      <div class="form-group">
        <label for="username">用户名:</label>
        <input
          type="text"
          v-model="username"
          id="username"
          class="form-control"
          required
          minlength="4"
          maxlength="20"
          pattern="^[a-zA-Z0-9_]+$"
          title="用户名只能包含字母、数字和下划线，长度为4到20个字符"
        />
        <small v-if="username.length < 4 || username.length > 20" class="error-text">用户名长度应为4到20个字符。</small>
        <small v-if="!/^[a-zA-Z0-9_]+$/.test(username)" class="error-text">用户名只能包含字母、数字和下划线。</small>
      </div>
      <div class="form-group">
        <label for="password">密码:</label>
        <input
          type="password"
          v-model="password"
          id="password"
          class="form-control"
          required
          minlength="8"
          maxlength="20"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$"
          title="密码必须包含至少一个大写字母、一个小写字母、一个数字和一个特殊字符，长度为8到20个字符"
        />
        <small v-if="password.length < 8 || password.length > 20" class="error-text">密码长度应为8到20个字符。</small>
        <small v-if="!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/.test(password)" class="error-text">密码必须包含至少一个大写字母、一个小写字母、一个数字和一个特殊字符。</small>
      </div>
      <div class="form-group">
        <label for="email">邮箱:</label>
        <input
          type="email"
          v-model="email"
          id="email"
          class="form-control"
          required
        />
      </div>
      <button type="submit" class="submit-button">注册</button>
    </form>

    <!-- 注册成功信息 -->
    <div v-if="registeredUser" class="success-message">
      <h3>注册成功！</h3>
      <p>用户名: {{ registeredUser.username }}</p>
      <p>用户ID: {{ registeredUser.id }}</p>
    </div>

    <!-- 错误信息 -->
    <div v-if="errorMessage" class="error-message">
      <h3>注册失败</h3>
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      email: '',
      registeredUser: null,
      errorMessage: '', // 用于存储错误信息
    };
  },
  methods: {
    async register() {
      try {
        const response = await fetch('https://api.woow.us.kg/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: this.username,
            password: this.password,
            email: this.email,
            registration_ip: '127.0.0.1', // 示例 IP，可以根据需求调整
          }),
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.error);
        }

        const data = await response.json();
        this.registeredUser = data;
        this.errorMessage = ''; // 成功注册时清空错误信息
      } catch (error) {
        this.errorMessage = error.message; // 显示错误信息
        this.registeredUser = null; // 失败时清空成功的用户信息
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.success-message {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #d4edda;
  border-radius: 4px;
  background-color: #d4edda;
  color: #155724;
}

.error-message {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #f8d7da;
  border-radius: 4px;
  background-color: #f8d7da;
  color: #721c24;
}

.error-text {
  color: #721c24;
  font-size: 0.875rem;
  margin-top: 5px;
}

</style>
