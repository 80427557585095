<template>
  <div class="container">
    <h2 class="mb-4">用户管理</h2>
    <button class="btn btn-primary mb-3" @click="showAddUserForm">增加用户</button>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>ID</th>
          <th>用户名</th>
          <th>邮箱</th>
          <th>注册日期</th>
          <th>注册IP</th>
          <th>等级</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.id }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.registration_date }}</td>
          <td>{{ user.registration_ip }}</td>
          <td>{{ user.user_role }}</td>
          <td>
            <button class="btn btn-secondary btn-sm me-2" @click="editUser(user.id)">编辑</button>
            <button class="btn btn-danger btn-sm" @click="deleteUser(user.id)">删除</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 用户模态框 -->
    <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="userModalLabel">{{ editUserId === null ? '添加用户' : '编辑用户' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editUserId === null ? addUser() : updateUser()">
              <div class="mb-3">
                <label for="username" class="form-label">用户名</label>
                <input id="username" v-model="currentUser.username" class="form-control" placeholder="请输入用户名" />
                <div class="form-text">用户名是唯一的，用户登录时使用。</div>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">密码</label>
                <input id="password" v-model="currentUser.password" class="form-control" placeholder="请输入密码" type="password" />
                <div class="form-text">设置用户的登录密码。</div>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">邮箱</label>
                <input id="email" v-model="currentUser.email" class="form-control" placeholder="请输入邮箱" />
                <div class="form-text">用户的电子邮件地址，用于通知和密码重置。</div>
              </div>
              <div class="mb-3">
                <label for="registration_date" class="form-label">注册日期</label>
                <input id="registration_date" v-model="currentUser.registration_date" class="form-control" placeholder="选择注册日期" type="date" />
                <div class="form-text">用户注册的日期。</div>
              </div>
              <div class="mb-3">
                <label for="registration_ip" class="form-label">注册IP</label>
                <input id="registration_ip" v-model="currentUser.registration_ip" class="form-control" placeholder="请输入注册IP" />
                <div class="form-text">用户注册时的IP地址。</div>
              </div>
              <div class="mb-3">
                <label for="user_role" class="form-label">用户等级</label>
                <select id="user_role" v-model="currentUser.user_role" class="form-control">
                  <option v-for="level in 5" :key="level" :value="level">{{ level }}</option>
                </select>
                <div class="form-text">用户的权限等级，1为最低，5为最高。</div>
              </div>
              <button type="submit" class="btn btn-primary">{{ editUserId === null ? '添加' : '更新' }}</button>
              <button type="button" class="btn btn-secondary" @click="resetUserForm" data-bs-dismiss="modal">取消</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      users: [],
      currentUser: {
        username: '',
        password: '',
        email: '',
        registration_date: '',
        registration_ip: '',
        user_role: 1
      },
      editUserId: null
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      axios.get('https://api.woow.us.kg/api/admin/users')
        .then(response => {
          this.users = response.data;
        })
        .catch(error => {
          console.error('获取用户数据失败:', error);
          alert('无法获取用户数据');
        });
    },
    showAddUserForm() {
      this.currentUser = { username: '', password: '', email: '', registration_date: '', registration_ip: '', user_role: 1 };
      this.editUserId = null;
      if (window.bootstrap) {
        new window.bootstrap.Modal(document.getElementById('userModal')).show();
      }
    },
    addUser() {
      axios.post('https://api.woow.us.kg/api/admin/users', this.currentUser)
        .then(() => {
          this.fetchUsers();
          this.resetUserForm();
        })
        .catch(error => {
          console.error('添加用户失败:', error);
          alert('无法添加用户');
        });
    },
    editUser(id) {
      this.editUserId = id;
      const user = this.users.find(u => u.id === id);
      this.currentUser = { ...user };
      if (window.bootstrap) {
        new window.bootstrap.Modal(document.getElementById('userModal')).show();
      }
    },
    updateUser() {
      axios.put(`https://api.woow.us.kg/api/admin/users/${this.editUserId}`, this.currentUser)
        .then(() => {
          this.fetchUsers();
          this.resetUserForm();
        })
        .catch(error => {
          console.error('更新用户失败:', error);
          alert('无法更新用户');
        });
    },
    deleteUser(id) {
      axios.delete(`https://api.woow.us.kg/api/admin/users/${id}`)
        .then(() => {
          this.fetchUsers();
        })
        .catch(error => {
          console.error('删除用户失败:', error);
          alert('无法删除用户');
        });
    },
    resetUserForm() {
      this.currentUser = { username: '', password: '', email: '', registration_date: '', registration_ip: '', user_role: 1 };
      this.editUserId = null;
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.table {
  margin-top: 20px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.modal-dialog {
  max-width: 800px;
}

.form-label {
  font-weight: bold;
}

.form-text {
  font-size: 0.9rem;
}

.btn-close {
  outline: none;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
}

.modal-content {
  border-radius: 0.5rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}
</style>
