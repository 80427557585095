<template>
  <div class="container-fluid">
    <h2>节点管理</h2>
    <button class="btn btn-primary mb-3" @click="showAddNodeForm">增加节点</button>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>节点名称</th>
            <th>节点类型</th>
            <th>节点链接</th>
            <th>节点状态</th>
            <th>节点组</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="node in nodes" :key="node.id">
            <td class="text-center">{{ node.id }}</td>
            <td>{{ node.node_name }}</td>
            <td>{{ node.node_type }}</td>
            <td>{{ node.node_link }}</td>
            <td>{{ node.node_status }}</td>
            <td>{{ node.node_group }}</td>
            <td>
              <button class="btn btn-secondary btn-sm me-2" @click="editNode(node.id)">编辑</button>
              <button class="btn btn-danger btn-sm" @click="deleteNode(node.id)">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 节点模态框 -->
    <div class="modal fade" id="nodeModal" tabindex="-1" aria-labelledby="nodeModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="nodeModalLabel">{{ editNodeId === null ? '添加节点' : '编辑节点' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="editNodeId === null ? addNode() : updateNode()">
              <div class="mb-3">
                <label for="node_name" class="form-label">节点名称</label>
                <input id="node_name" v-model="currentNode.node_name" class="form-control" placeholder="请输入节点名称" />
                <div class="form-text">节点的名称，用于标识该节点。</div>
              </div>
              <div class="mb-3">
                <label for="node_type" class="form-label">节点类型</label>
                <input id="node_type" v-model="currentNode.node_type" class="form-control" placeholder="请输入节点类型" />
                <div class="form-text">节点的类型，例如 '类型A'、'类型B' 等。</div>
              </div>
              <div class="mb-3">
                <label for="node_link" class="form-label">节点链接</label>
                <input id="node_link" v-model="currentNode.node_link" class="form-control" placeholder="请输入节点链接" />
                <div class="form-text">节点的链接地址。</div>
              </div>
              <div class="mb-3">
                <label for="node_status" class="form-label">节点状态</label>
                <input id="node_status" v-model="currentNode.node_status" class="form-control" placeholder="请输入节点状态" />
                <div class="form-text">节点的状态，例如 '启用'、'禁用'。</div>
              </div>
              <div class="mb-3">
                <label for="node_group" class="form-label">节点组</label>
                <input id="node_group" v-model="currentNode.node_group" class="form-control" placeholder="请输入节点组" />
                <div class="form-text">节点所属的组或分类。</div>
              </div>
              <button type="submit" class="btn btn-primary">{{ editNodeId === null ? '添加' : '更新' }}</button>
              <button type="button" class="btn btn-secondary" @click="resetNodeForm" data-bs-dismiss="modal">取消</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      nodes: [],
      currentNode: {
        node_name: '',
        node_type: '',
        node_link: '',
        node_status: '',
        node_group: ''
      },
      editNodeId: null
    };
  },
  created() {
    this.fetchNodes();
  },
  methods: {
    fetchNodes() {
      axios.get('https://api.woow.us.kg/api/admin/nodes')
        .then(response => {
          this.nodes = response.data;
        })
        .catch(error => {
          console.error('获取节点数据失败:', error);
          alert('无法获取节点数据');
        });
    },
    showAddNodeForm() {
      this.currentNode = { node_name: '', node_type: '', node_link: '', node_status: '', node_group: '' };
      this.editNodeId = null;
      if (window.bootstrap) {
        new window.bootstrap.Modal(document.getElementById('nodeModal')).show();
      }
    },
    addNode() {
      axios.post('https://api.woow.us.kg/api/admin/nodes', this.currentNode)
        .then(() => {
          this.fetchNodes();
          this.resetNodeForm();
        })
        .catch(error => {
          console.error('添加节点失败:', error);
          alert('无法添加节点');
        });
    },
    editNode(id) {
      this.editNodeId = id;
      const node = this.nodes.find(n => n.id === id);
      this.currentNode = { ...node };
      if (window.bootstrap) {
        new window.bootstrap.Modal(document.getElementById('nodeModal')).show();
      }
    },
    updateNode() {
      axios.put(`https://api.woow.us.kg/api/admin/nodes/${this.editNodeId}`, this.currentNode)
        .then(() => {
          this.fetchNodes();
          this.resetNodeForm();
        })
        .catch(error => {
          console.error('更新节点失败:', error);
          alert('无法更新节点');
        });
    },
    deleteNode(id) {
      axios.delete(`https://api.woow.us.kg/api/admin/nodes/${id}`)
        .then(() => {
          this.fetchNodes();
        })
        .catch(error => {
          console.error('删除节点失败:', error);
          alert('无法删除节点');
        });
    },
    resetNodeForm() {
      this.currentNode = { node_name: '', node_type: '', node_link: '', node_status: '', node_group: '' };
      this.editNodeId = null;
    }
  }
};
</script>

<style scoped>
.container-fluid {
  padding: 0 15px;
}

.table-responsive {
  overflow-x: auto;
}

.table {
  font-size: 0.9rem;
  width: 100%;
}

.table td, .table th {
  vertical-align: middle; /* 垂直居中 */
}

.table td {
  max-width: 200px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.form-control {
  border-radius: 0.25rem;
}
</style>
