<template>
  <div class="container">
    <h2 class="title">Shadowrocket 使用说明</h2>

    <div class="section">
      <h3 class="section-title">介绍</h3>
      <p>
        Shadowrocket 是一款功能强大的 iOS VPN 客户端，支持各种协议和配置，包括 Shadowsocks、Vmess、Trojan 等。它能够帮助用户绕过地理限制，保护上网隐私。
      </p>
    </div>

    <div class="section">
      <h3 class="section-title">安装步骤</h3>
      <ol>
        <li>打开 App Store 应用。</li>
        <li>在搜索框中输入“Shadowrocket”，并找到该应用。</li>
        <li>点击“获取”按钮下载并安装应用。</li>
        <li>安装完成后，打开 Shadowrocket 应用。</li>
      </ol>
    </div>

    <div class="section">
      <h3 class="section-title">使用说明</h3>
      <ol>
        <li>在 Shadowrocket 主界面中，点击右上角的“+”按钮添加新的代理配置。</li>
        <li>选择代理类型（如 Shadowsocks、Vmess、Trojan），并输入相应的服务器信息。</li>
        <li>配置完成后，点击右上角的“保存”按钮。</li>
        <li>在主界面中，选择刚刚创建的配置，并点击“连接”按钮。</li>
        <li>成功连接后，您将能够通过 Shadowrocket 访问被封锁的内容。</li>
      </ol>
    </div>

    <div class="section">
      <h3 class="section-title">注意事项</h3>
      <ul>
        <li>请确保您的服务器信息准确无误。</li>
        <li>某些网站可能需要额外的设置或配置才能正常访问。</li>
        <li>使用 VPN 服务时，请遵守当地法律法规。</li>
      </ul>
    </div>

    <div class="section">
      <h3 class="section-title">支持与反馈</h3>
      <p>
        如果在使用过程中遇到问题或有任何反馈，请访问 Shadowrocket 的官方网站或通过 App Store 提交反馈。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShadowrocketGuide',
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

ol {
  margin: 0;
  padding-left: 20px;
}

ul {
  margin: 0;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

p {
  color: #555;
}
</style>
