<template>
  <div class="login-container">
    <h2 v-if="!isLoggedIn">登录</h2>
    <form v-if="!isLoggedIn" @submit.prevent="login">
      <div class="form-group">
        <label for="username">用户名</label>
        <input type="text" id="username" v-model="username" required>
      </div>
      <div class="form-group">
        <label for="password">密码</label>
        <input type="password" id="password" v-model="password" required>
      </div>
      <button type="submit" class="btn">登录</button>
    </form>

    <!-- 显示错误信息 -->
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>

    <!-- 登录成功后显示的消息和用户信息 -->
    <div v-if="isLoggedIn" class="success-message">
      <h3>账户信息</h3>
      <table class="table">
        <tbody>
          <tr>
            <td>用户名:</td>
            <td>{{ username }}</td>
          </tr>
          <tr>
            <td>用户ID:</td>
            <td>{{ userId }}</td>
          </tr>
          <tr>
            <td>邮箱:</td>
            <td>{{ email }}</td>
          </tr>
          <tr>
            <td>注册时间:</td>
            <td>{{ registrationDate }}</td>
          </tr>
          <tr>
            <td>用户等级:</td>
            <td>{{ userRole }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      isLoggedIn: false,
      userId: '',
      email: '',
      registrationDate: '',
      userRole: '' // 新增用户角色字段
    };
  },
  methods: {
    async login() {
      try {
        const response = await fetch('https://api.woow.us.kg/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: this.username,
            password: this.password,
          }),
        });

        if (!response.ok) {
          const error = await response.json();
          this.errorMessage = error.message || '登录失败，请稍后再试。';
          this.isLoggedIn = false;
          return;
        }

        const data = await response.json();

        // 存储登录状态和用户信息到 localStorage
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('username', data.username);
        localStorage.setItem('userId', data.id);
        localStorage.setItem('email', data.email);
        localStorage.setItem('registrationDate', data.registration_date);
        localStorage.setItem('userRole', data.user_role); // 存储用户角色

        // 更新组件数据
        this.isLoggedIn = true;
        this.userId = data.id;
        this.email = data.email;
        this.registrationDate = data.registration_date;
        this.userRole = data.user_role; // 更新用户角色

        // 重定向到首页
        window.location.href = '/login';
      } catch (error) {
        console.error('登录失败:', error.message);
        this.errorMessage = '登录失败，请稍后再试。';
        this.isLoggedIn = false;
      }
    },
  },
  mounted() {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    const storedUsername = localStorage.getItem('username');
    const storedUserId = localStorage.getItem('userId');
    const storedEmail = localStorage.getItem('email');
    const storedRegistrationDate = localStorage.getItem('registrationDate');
    const storedUserRole = localStorage.getItem('userRole'); // 读取用户角色

    this.isLoggedIn = storedLoginStatus === 'true';
    this.username = storedUsername || '';
    this.userId = storedUserId || '';
    this.email = storedEmail || '';
    this.registrationDate = storedRegistrationDate || '';
    this.userRole = storedUserRole || ''; // 更新用户角色
  },
};
</script>

<style scoped>
/* 样式同之前保持一致 */
.login-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #e9ecef; /* 背景颜色 */
}

h2, h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.btn:hover {
  background-color: #0056b3;
}

.success-message {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #d4edda;
  border-radius: 4px;
  background-color: #ffffff;
  color: #155724;
}

.error-message {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #f8d7da;
  border-radius: 4px;
  background-color: #f8d7da;
  color: #721c24;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table td {
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  text-align: left; /* 单元格左对齐 */
}

.table td:first-child {
  font-weight: bold;
}
</style>
