import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/UserLogin.vue';
import UserRegister from '../components/UserRegister.vue';
import Home from '../components/HomePage.vue'; // 导入首页组件
import NodeList from '../components/NodeList.vue';
import AdminPanel from '../components/AdminPanel.vue';
import PasswordProtect from '../components/PasswordProtect.vue';
import UserManagement from '../components/UserManagement.vue';
import NodeManagement from '../components/NodeManagement.vue';
import SoftwareDownload from '../components/SoftwareDownload.vue'; // 导入下载组件
import ShadowrocketGuide from '../components/ShadowrocketGuide.vue'; // 引入 ShadowrocketGuide 组件
import NekoBoxGuide from '../components/NekoBoxGuide.vue'; // 引入 NekoBoxGuide 组件
import V2RayNGGuide from '../components/V2RayNGGuide.vue'; // 引入 V2RayNGGuide 组件


const routes = [
  { path: '/', component: Home }, // 设置首页的路由
  { path: '/register', component: UserRegister },
  { path: '/login', component: UserLogin },
  { path: '/nodes', component: NodeList },// 节点列表路由
  { path: '/software-download', component: SoftwareDownload },// 软件下载
  { path: '/shadowrocket-guide', component: ShadowrocketGuide }, // 添加 ShadowrocketGuide 路由
  { path: '/nekobox-guide', component: NekoBoxGuide }, // 添加 NekoBoxGuide 路由
  { path: '/v2rayng-guide', component: V2RayNGGuide }, // 添加 V2RayNGGuide 路由
  {
    path: '/admin',
    component: PasswordProtect, // 这里将 PasswordProtect 组件作为入口组件
    children: [
      {
        path: '',
        name: 'adminPanel',
        component: AdminPanel,
        meta: { requiresAuth: true }
      },
      {
        path: 'userManagement',
        name: 'userManagement',
        component: UserManagement,
        meta: { requiresAuth: true }
      },
      {
        path: 'nodeManagement',
        name: 'nodeManagement',
        component: NodeManagement,
        meta: { requiresAuth: true }
      }
    ]
  },
  // 其他路由...
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
