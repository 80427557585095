<template>
  <div class="password-protect">
    <div v-if="!isAuthenticated" class="auth-form">
      <h2>管理员登录</h2>
      <form @submit.prevent="validatePassword">
        <div class="mb-3">
          <label for="password" class="form-label">密码</label>
          <input type="password" id="password" v-model="password" class="form-control" required>
        </div>
        <button type="submit" class="btn btn-primary">登录</button>
        <p v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</p>
      </form>
    </div>
    <div v-else class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      password: '',
      isAuthenticated: false,
      errorMessage: ''
    };
  },
  methods: {
    async validatePassword() {
      try {
        const response = await axios.post('https://api.woow.us.kg/api/adminlogin', { password: this.password });
        if (response.data.success) {
          this.isAuthenticated = true;
          localStorage.setItem('isAuthenticated', 'true');
          this.$router.push({ name: 'adminPanel' }); // 登录成功后重定向到管理员面板
        }
      } catch (error) {
        this.errorMessage = '密码错误，请重试';
      }
    }
  },
  mounted() {
    this.isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  }
};
</script>

<style scoped>
.password-protect {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.auth-form {
  max-width: 400px;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background-color: #ffffff;
}

.content {
  width: 100%;
}
</style>
