<template>
  <div class="admin-panel container">
    <h2 class="my-4">管理员面板</h2>
    <div class="button-group mb-4">
      <button class="btn btn-primary me-3" @click="navigateTo('userManagement')">用户管理</button>
      <button class="btn btn-primary me-3" @click="navigateTo('nodeManagement')">节点管理</button>
      <button class="btn btn-secondary" @click="logout">注销</button>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    navigateTo(page) {
      this.$router.push({ name: page });
    },
    logout() {
      localStorage.removeItem('isAuthenticated');
      alert('注销成功');
      window.location.href = '/admin'; // 注销后重定向到登录页面
    }
  }
};
</script>

<style scoped>
.admin-panel {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

h2 {
  text-align: center;
  font-size: 2rem;
  color: #343a40;
}

.button-group {
  display: flex;
  justify-content: center;
}

.btn {
  font-size: 1rem;
  padding: 10px 20px;
}

.content {
  margin-top: 20px;
}
</style>
