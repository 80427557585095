import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // 引入 Vuex Store
// 引入 Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// 引入 Bootstrap JavaScript
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

createApp(App).use(router).use(store).mount('#app');
