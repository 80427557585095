<template>
  <div class="container-fluid">
    <h2 class="text-center"></h2>
    <div class="group-container">
      <div v-for="(groupNodes, group) in groupedNodes" :key="group" class="group-row">
        <h3 class="group-title">节点组: {{ group }}</h3>
        <div class="card-container">
          <div v-for="node in groupNodes" :key="node.id" class="card mb-3">
            <div class="card-body">
              <h5 class="card-title" style="text-align: center;">{{ node.node_name }}</h5>
              <p class="card-text card-text-center">
                <strong></strong>
                <span :class="getNodeStatusClass(node.node_status)">
                  {{ getNodeStatus(node.node_status) }}
                </span>
              </p>
              <p class="card-text">
                <strong class="node-link-label">节点链接:</strong>
              </p>
              <p class="card-text">
                <span v-if="isLoggedIn">
                  <span v-if="hasAccessToNodeGroup(node.node_group)" class="node-link">
                    {{ node.node_link }}
                  </span>
                  <span v-else class="alert-text">
                    权限不够请提升你的用户权限
                  </span>
                </span>
                <span v-else class="alert-text">
                  <a href="/login" class="login-link">请先登录才能查看节点链接</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-if="nodes.length === 0" class="text-center">还未添加节点</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NodeList',
  data() {
    return {
      nodes: [],
      isLoggedIn: false, // 用于存储用户登录状态
      userRole: 0, // 用户角色
      searchQuery: '', // 用于筛选节点的搜索关键字
    };
  },
  computed: {
    filteredNodes() {
      if (!this.searchQuery) return this.nodes;
      return this.nodes.filter(node =>
        node.node_name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    groupedNodes() {
      return this.filteredNodes.reduce((groups, node) => {
        const group = node.node_group;
        if (!groups[group]) {
          groups[group] = [];
        }
        groups[group].push(node);
        return groups;
      }, {});
    }
  },
  methods: {
    getNodeStatus(status) {
      return status > 0 ? '在线' : '离线';
    },
    getNodeStatusClass(status) {
      return status > 0 ? 'node-status-online' : 'node-status-offline';
    },
    hasAccessToNodeGroup(group) {
      return this.userRole >= Number(group);
    }
  },
  mounted() {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    this.isLoggedIn = storedLoginStatus === 'true';

    this.userRole = Number(localStorage.getItem('userRole')) || 0;

    axios.get('https://api.woow.us.kg/api/user/status')
      .then(response => {
        this.isLoggedIn = response.data.isLoggedIn || this.isLoggedIn;
        this.userRole = response.data.userRole || this.userRole;
      })
      .catch(error => {
        console.error('获取用户状态时出错:', error);
      });

    axios.get('https://api.woow.us.kg/api/nodes')
      .then(response => {
        this.nodes = response.data;
      })
      .catch(error => {
        console.error('获取节点数据时出错:', error);
      });
  },
};
</script>

<style scoped>
.container-fluid {
  padding: 0 15px;
  background-color: #f8f9fa; /* 背景颜色统一 */
}

.text-center {
  text-align: center;
  color: #343a40; /* 统一字体颜色 */
}

.group-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* 每个组之间的间隔 */
}

.group-row {
  margin-bottom: 20px; /* 每个组底部的间距 */
}

.group-title {
  text-align: center; /* 确保标题居中 */
  margin-bottom: 10px; /* 组标题下方的间距 */
  font-size: 1.5rem; /* 组标题的字体大小 */
  color: #495057; /* 统一标题颜色 */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* 卡片之间的间隔 */
  justify-content: center; /* 居中对齐 */
}

.card {
  width: 300px; /* 卡片宽度 */
  border: 1px solid #dee2e6; /* 统一边框 */
  border-radius: 0.75rem; /* 增加圆角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 增强阴影效果 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 背景色和阴影过渡效果 */
  overflow: hidden; /* 隐藏超出部分 */
  background-color: #ffffff; /* 卡片背景色 */
  display: flex;
  flex-direction: column; /* 垂直方向布局 */
}

.card-body {
  flex: 1; /* 使卡片内容区域扩展 */
  padding: 1.5rem; /* 增加内边距 */
}

.card-title {
  font-size: 1.25rem; /* 增大标题字体 */
  margin-bottom: 1rem; /* 标题下方间距 */
}

.card-text {
  margin-bottom: 0.11rem; /* 内容下方间距 */
  font-size: 0.88rem; /* 调整字体大小 */
}

.card-text-center {
  text-align: center; /* 文本居中对齐 */
}

.node-status-online {
  color: #155724; /* 绿色文字 */
  background-color: #d4edda; /* 绿色背景 */
  padding: 0.25rem 0.5rem; /* 内边距 */
  border-radius: 0.25rem; /* 圆角 */
}

.node-status-offline {
  color: #721c24; /* 红色文字 */
  background-color: #f8d7da; /* 红色背景 */
  padding: 0.25rem 0.5rem; /* 内边距 */
  border-radius: 0.25rem; /* 圆角 */
}

p {
  color: #777;
  margin-top: 20px; /* 增加顶部间距 */
}

.card-text strong {
  color: #007bff; /* 改变字体颜色为蓝色 */
  font-weight: bold; /* 确保字体加粗 */
  font-size: 1rem; /* 调整字体大小 */
}

.card-text .node-link-label {
  display: inline-block;
  padding: 0.25rem 0.5rem; /* 增加内边距 */
  background-color: #e9ecef; /* 背景颜色 */
  border-radius: 0.25rem; /* 圆角 */
  border: 1px solid #ced4da; /* 边框颜色 */
  color: #495057; /* 字体颜色 */
}

.alert-text {
  font-weight: bold; /* 字体加粗 */
  font-size: 1rem; /* 调整字体大小 */
  color: #ffffff; /* 白色文字 */
  background-color: #dc3545; /* 红色背景 */
  padding: 0.5rem; /* 增加内边距 */
  border-radius: 0.25rem; /* 圆角 */
  text-align: center; /* 居中对齐 */
  margin: 0 auto; /* 自动水平居中 */
  display: block; /* 确保 p 标签作为块级元素显示 */
  max-width: 300px; /* 最大宽度，以防止文本过宽 */
}

.login-link {
  color: #ffffff; /* 白色文字 */
  text-decoration: underline; /* 下划线 */
  cursor: pointer; /* 手型光标 */
}

.login-link:hover {
  color: #cce5ff; /* 悬停时的颜色 */
}
</style>
