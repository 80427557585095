<template>
  <div class="home">
    <header class="hero">
      <div class="hero-container">
        <div class="hero-content">
          <h1>欢迎来到车车倒免费机场</h1>
          <p>为您提供免费的 VPN 节点订阅，帮助您安全、匿名地浏览互联网。</p>
          <div class="button-group">
            <a href="/register" class="cta-button">立即注册</a>
            <a href="/login" class="cta-button secondary">立即登录</a>
          </div>
        </div>
      </div>
    </header>
    <section class="features">
      <h2>我们的服务特点</h2>
      <div class="feature-item">
        <h3>免费节点订阅</h3>
        <p>提供多多地免费 VPN 节点，您可以根据需要选择最适合您的节点进行连接，无需支付额外费用。</p>
      </div>
      <div class="feature-item">
        <h3>高效的隐私保护</h3>
        <p>使用我们的 VPN 节点，您的在线活动将受到保护，您的个人信息将不会被泄露。我们致力于保护您的隐私。</p>
      </div>
      <div class="feature-item">
        <h3>全球服务器选择</h3>
        <p>拥有分布在全球的多个 VPN 节点，您可以选择不同国家或地区的服务器，以优化您的网络体验。</p>
      </div>
      <div class="feature-item">
        <h3>简单易用</h3>
        <p>服务易于使用，按照简单步骤即可快速配置并开始使用 VPN。无需复杂设置，轻松上手。</p>
      </div>
    </section>
    <section class="usage-guide">
      <h2>使用指南</h2>
      <p>要开始使用我们的免费 VPN 服务，请按照以下步骤操作：</p>
      <ol>
        <li>访问我们的 <a href="/register">注册页面</a> 并创建一个账户。</li>
        <li>登录后，您将获得免费的 VPN 节点订阅信息。</li>
        <li>下载并配置相应的 VPN 客户端软件，输入我们提供的节点信息。</li>
        <li>连接 VPN 节点，即可享受安全、匿名的网络体验。</li>
      </ol>
    </section>
    <footer class="footer">
      <p>&copy; 2024 车车倒免费机场 | <a href="/contact">联系我们</a></p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
.home {
  font-family: 'Arial', sans-serif;
}

.hero {
  background: linear-gradient(135deg, #007bff, #00c6ff);
  color: #fff;
  padding: 80px 20px;
  text-align: center;
  border-bottom: 4px solid #007bff;
}

.hero-container {
  max-width: 1000px; /* 最大宽度 */
  margin: 0 auto; /* 居中对齐 */
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px; /* 按钮之间的间隔 */
}

.cta-button {
  background-color: #007bff;
  color: #fff;
  padding: 15px 25px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}

.cta-button.secondary {
  background-color: #6c757d;
}

.cta-button.secondary:hover {
  background-color: #5a6268;
}

.features {
  padding: 40px 20px;
  background-color: #f8f9fa;
}

.features h2 {
  text-align: center;
  margin-bottom: 30px;
}

.feature-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin: 10px auto;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
  margin-top: 0;
}

.usage-guide {
  padding: 40px 20px;
}

.usage-guide ol {
  padding-left: 20px;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
</style>
