<template>
  <div class="navbar-container">
    <nav class="navbar">
      <ul class="nav-list">
        <li class="nav-item"><a href="/" class="nav-link">首页</a></li>
        <li class="nav-item"><a href="/nodes" class="nav-link">节点列表</a></li>
        <li class="nav-item"><a href="/software-download" class="nav-link">软件下载</a></li>

        <!-- 如果用户未登录，显示登录和注册按钮 -->
        <li v-if="!isLoggedIn" class="nav-item"><a href="/login" class="nav-link">登录</a></li>
        <li v-if="!isLoggedIn" class="nav-item"><a href="/register" class="nav-link">注册</a></li>

        <!-- 如果用户已登录，显示用户名和退出按钮 -->
        <li v-if="isLoggedIn" class="nav-item"><a href="/login" class="nav-link">用户：{{ username }}</a></li>
        <li v-if="isLoggedIn" class="nav-item"><a href="#" @click="logout" class="nav-link">退出登录</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false, // 登录状态
      username: '', // 登录用户的用户名
    };
  },
  mounted() {
    // 检查 localStorage 中是否有登录状态
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    const storedUsername = localStorage.getItem('username'); // 假设你将用户名也存储在 localStorage 中

    if (storedLoginStatus === 'true') {
      this.isLoggedIn = true;
      this.username = storedUsername || ''; // 如果用户名存在，则设置为当前用户名
    }
  },
  methods: {
    logout() {
      // 清除登录状态和用户名
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('username');
      this.isLoggedIn = false;
      this.username = '';

      // 你可以选择重定向到登录页面
      window.location.href = '/';
    },
  },
};
</script>

<style scoped>
/* 样式同之前保持一致 */
.navbar-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.navbar {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border: 1px solid #e0e0e0;
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 15px;
}

.nav-item {
  border-radius: 8px;
  overflow: hidden;
}

.nav-link {
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover {
  background-color: #007bff;
  color: white;
}
</style>
