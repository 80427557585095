// store/index.js
import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      isLoggedIn: false // 默认未登录
    };
  },
  mutations: {
    setLoginStatus(state, status) {
      state.isLoggedIn = status;
    }
  }
});

export default store;

